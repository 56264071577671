.banner {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}

.banner::before {
    content: "";
    background: url("https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading { 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner h1{
    font-size: 2.4rem;
}

.banner p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .banner h1{
        font-size: 2rem;
    }
}