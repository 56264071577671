.popup {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    

    display:flex;
    justify-content: center;
    align-items: center;
}

.popup-inner { 
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color:rgb(12,12,12)  
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

h3 {
    text-align: center;
    color: white;
    line-height: 10; 
}
