.mask{
    width: 100%;
    height: 100vh;
}

.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;
}

.home-banner{
    height: 100%;
    width: 100%;
}

.home-banner .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.home-banner .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.home-banner .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}

.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px) {
    .home-banner .content h1{
        font-size: 3rem;
    }
    
    .home-banner .content p{
        font-size: 1.4rem;
        font-weight: 200;
        text-transform: uppercase;
    }
}